
.sidebar-search-results {
  td {
    border-left: 0px !important;
    border-right: 0px !important;
  }
  tr:last-child > td {
    border-bottom: 0px;
  }
}

.menu-favourites-list {
  :first-child {
    display: grid !important;
  }
}

.realitex-scrollable-div {
  overflow: auto;
  touch-action: none;
}

.realitex-nonscrollable-div {
  overflow: hidden;
  touch-action: none;
}

.realitex-scrollable-table {

  font-size: 13px;
  text-align: left;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure the header stays above other content */
  }

  th{
    background-color: #335B70;
    color: white;
    text-align: left;
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  tr {
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #fafbfb;
  }
  
  /* Apply styles to odd rows */
  tr:nth-child(odd) {
    background-color: #ffffff;
  }

  tr:hover {
    background-color: #dbd7d7;
  }

  td {
    text-align: left;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
    border-bottom: 1px solid #CCD6DB;
  }
}