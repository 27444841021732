
.navbar {
  position: fixed;
  z-index: 1000;
}

@media only screen and (max-width: 599px) {
  .menu-item-xs, .brand-img-xs {
    display: block !important;
  }
  .menu-item-sm, .brand-img-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .menu-item-xs, .brand-img-xs {
    display: none !important;
  }
  .menu-item-sm, .brand-img-sm {
    display: block !important;
  }
}

.favourites-icon {
  cursor: pointer;
  &:before{
      font-size: 22px !important;
  }
}
