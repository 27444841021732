
// For Textbox X button to clear it
  .clear-btn {
    transform: translateX(-100%);
    cursor: pointer;
    color: #888;
    font-size: 2.0em;
  }
  
  .clear-btn:hover {
    color: #333;
  }