.nestable-drag-handle {
  &:hover {
    cursor: move;
  }
}

.nestable-item {
  margin: 3px 0 0 !important;
  > .nestable-list {
    margin-top: 3px !important;
  }

  button {
    border: 0px;
    background-color: transparent;
    padding: 0px;
    &:focus,:focus-visible,:focus-within{
      outline: none;
    }
  }
}
